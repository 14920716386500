<template>
  <div class="text-center" >
      <iframe
        width="100%"
        height="300"
        :style="{width: this.width, height: this.height}"
        style="z-index:1300;"
        v-bind:src="this.videoUrl"
        v-bind:srcdoc="this.srcDoc"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; zoom; gyroscope; picture-in-picture"
        allowfullscreen
        :title="this.title"
      ></iframe>
  </div>


</template>
<script>


export default {
  name: 'LazyVideoPlayer',
  props: ['videoUrl', 'videoId', 'title','width','height','autoplay'],
  computed: {
    image: {
      get() {
        return `https://img.youtube.com/vi/${this.videoId.split("?")[0]}/hqdefault.jpg`;
      },
    },
    srcDoc: {
      get() {
        return this.autoplay ? undefined : `<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}
                img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.2em;text-align:center;font:28px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style>
                <a href='${this.videoUrl}'><img src='${this.image}' height="315" width="100%" style="opacity: 0.4; "><span>  ▶</span></a>` ;
      },
    },
  },
};
</script>
